import React, { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { FilterMatchMode } from 'primereact/api';
import { Button } from 'primereact/button';


const LibraryTable = ({ exerciseProcedureList, setExerciseVideoDialogVisible, setSelectedExercise, t }: any) => {
    const columns = [
        {
            field: "image",

        },
        {
            field: "name",

        },
        {
            field: "action",

        },
    ];

    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    });

    const onGlobalFilterChange = (e: any) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const header = () => {
        return (
            <div className="flex justify-content-between align-items-center">
                <p className='text-xl' style={{ color: "#727A99" }}>{t("systemExercises")}</p>
                <IconField iconPosition="left" className='w-20rem' >
                    <InputIcon className="pi pi-search" />
                    <InputText className='w-full' style={{ borderRadius: "10px" }} value={globalFilterValue} onChange={onGlobalFilterChange} placeholder={t("datatableSearchPlaceholderForLibrary")} />
                </IconField>
            </div>
        );
    };

    const imageBodyTemplate = (rowData: any) => {
        return (
            <img src={rowData.image} width={50} height={50} style={{ objectFit: "contain", backgroundColor: "#EBEDF2", borderRadius: "10px" }} />
        )
    }


    const actionButtons = (rowData: any) => {
        return (
            <Button
                icon={<img src="/playIcon.svg" width={20} height={20} />}
                onClick={() => { setExerciseVideoDialogVisible(true); setSelectedExercise(rowData) }}
                style={{
                    border: "none",
                    backgroundColor: "rgba(227, 80, 107, 0.9)",
                    borderRadius: "10px",
                    width: "2rem",
                    height: "2rem"
                }} />

        )
    }

    const dynamicColumns = columns.map((col: any) => {
        if (col.field === "image") {
            return (
                <Column
                    key="image"
                    field={col.field}
                    header={t(col.field)}
                    body={imageBodyTemplate}
                />
            );
        }
        else if (col.field === "name") {
            return (
                <Column
                    key="name"
                    field={col.field}
                    header={t(col.field)}
                    dataType='text'
                    style={{fontWeight:"bold",color:"#2D3967"}}
                    sortable
                />
            );
        }
        else {
            return (
                <Column
                    key="action"
                    field={col.field}
                    header={t(col.field)}
                    body={actionButtons}
                />
            );
        }
    });

    return (
        <div >
            <DataTable paginator rows={5} value={exerciseProcedureList}
                tableStyle={{ minWidth: '25rem' }} header={header} globalFilterFields={['name']}
                filters={filters}>
                {dynamicColumns}
            </DataTable>
        </div>
    )
}

export default LibraryTable