import { Accordion, AccordionTab } from "primereact/accordion";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Knob } from "primereact/knob";
import { useState } from "react";

const ExerciseDetails = ({ t, selectedExercise } : any) => {
    const [expandedRows, setExpandedRows] = useState<any>([]);
    const [data, setData] = useState<any>([
        {
            set: "01",
            startTime: "13:52:06",
            duration: "165",
            completedRepeat: "7",
            success: "80"
        },
        {
            set: "02",
            startTime: "13:52:06",
            duration: "165",
            completedRepeat: "7",
            success: "80"
        },
        {
            set: "03",
            startTime: "13:52:06",
            duration: "165",
            completedRepeat: "7",
            success: "80"
        },
    ])

    const [inlineData, setInlineData] = useState<any>([
        {
            repeat: "1",
            startTime: "13:52:06",
            duration: "165",
            referanceAngle: "90",
            appliedAngle: "85",
            fault: "2",
            success: "90"
        },
        {
            repeat: "1",
            startTime: "13:52:06",
            duration: "165",
            referanceAngle: "90",
            appliedAngle: "84",
            fault: "0",
            success: "70"
        },
        {
            repeat: "1",
            startTime: "13:52:06",
            duration: "165",
            referanceAngle: "90",
            appliedAngle: "8",
            fault: "1",
            success: "80"
        },
    ])

    const accordionDatatableColumns = [
        {
            field: "set",
        },
        {
            field: "startTime",
        },
        {
            field: "duration",
        },
        {
            field: "completedRepeat",
        },
        {
            field: "success",
        }
    ]

    const inlineAccordionDatatableColumns = [
        {
            field: "repeat"
        },
        {
            field: "startTime"
        },
        {
            field: "duration"
        },
        {
            field: "referanceAngle"
        },
        {
            field: "appliedAngle"
        },
        {
            field: "fault"
        },
        {
            field: "success"
        }
    ]

    const setBodyTemplate = (rowData: any) => {
        return (
            <div className="p-4 bg-blue-900 border-circle w-3rem h-3rem flex flex-column justify-content-center align-items-center text-white">
                <span className="text-sm">
                    Set
                </span>
                <span className="text-sm">
                    {rowData.set}
                </span>
            </div>
        )
    }

    const durationBodyTemplate = (rowData : any) => {
        return <span>{rowData.duration} {t("secs")}</span>
    }

    const completedRepeatBodyTemplate = (rowData : any) => {
        return <span>{rowData.completedRepeat}/10</span>
    }

    const successBodyTemplate = (rowData : any) => {
        return <Knob value={rowData.success} valueColor="#009C47" rangeColor="#D8D8D8" size={75} valueTemplate={'{value}%'} />
    }

    const accordionDatatableDynamicColumns = accordionDatatableColumns.map((col : any) => {
        if(col.field === "set") {
            return <Column key={col.field} field={col.field} header="Set" body={setBodyTemplate} />
        } else if (col.field === "startTime") {
            return <Column key={col.field} field={col.field} header={t("startTime")} />
        } else if (col.field === "duration") {
            return <Column key={col.field} field={col.field} header={t("duration")} body={durationBodyTemplate} />
        } else if (col.field === "completedRepeat") {
            return <Column key={col.field} field={col.field} header={t("completedRepeat")} body={completedRepeatBodyTemplate} />
        } else if (col.field === "success") {
            return <Column key={col.field} field={col.field} header={t("success")} body={successBodyTemplate} />
        }
    })

    const repeatBodyTemplate = (rowData : any) => {
        return <span>{t("repeat")} {rowData.repeat}</span>
    }

    const referanceAngleBodyTemplate = (rowData : any) => {
        return <span>{rowData.referanceAngle} {t("degree")}</span>
    }

    const appliedAngleBodyTemplate = (rowData : any) => {
        return <span>{rowData.appliedAngle} {t("degree")}</span>
    }

    const inlineTableSuccessBodyTemplate = (rowData : any) => {
        return <span>{rowData.success}%</span>
    }

    const inlineAccordionDatatableDynamicColumns = inlineAccordionDatatableColumns.map((col : any) => {
        if(col.field === "repeat") {
            return <Column key={col.field} field={col.field} header={t("repeat")} body={repeatBodyTemplate} />
        } else if (col.field === "startTime") {
            return <Column key={col.field} field={col.field} header={t("startTime")} />
        } else if (col.field === "duration") {
            return <Column key={col.field} field={col.field} header={t("duration")} body={durationBodyTemplate} />
        } else if (col.field === "referanceAngle") {
            return <Column key={col.field} field={col.field} header={t("referenceAngle")} body={referanceAngleBodyTemplate} />
        } else if (col.field === "appliedAngle") {
            return <Column key={col.field} field={col.field} header={t("appliedAngle")} body={appliedAngleBodyTemplate} />
        } else if (col.field === "fault") {
            return <Column key={col.field} field={col.field} header={t("fault")} />
        } else if (col.field === "success") {
            return <Column key={col.field} field={col.field} header={t("success")} body={inlineTableSuccessBodyTemplate} />
        }
    })

    const header = (day : string) => {
        return (
            <div className="flex justify-content-between align-items-center">
                <div className="w-full">
                    <span className="text-md text-white">{day}</span>
                </div>
                <div className="flex gap-3 text-black-alpha-90 font-light w-full">
                    <span className="text-md">
                        3 {t("sets")}
                    </span>
                    <span className="text-md">
                        15 {t("repeats")}
                    </span>
                    <span className="text-md">
                        30 {t('degree')}
                    </span>
                    <span className="text-md">
                        15 {t("secs")}
                    </span>
                </div>
                <div className="text-white">
                    <span className="text-md">
                        22.10.2024
                    </span>
                </div>
            </div>
        )
    }

    const rowExpansionTemplate = (row : any) => {
        return (
            <DataTable className="accordion-inline-datatable" value={inlineData}>
                {inlineAccordionDatatableDynamicColumns}
            </DataTable>
        )
    }


    return (
        <>
            <div className="flex justify-content-between align-items-center">
                <span className="text-2xl">{selectedExercise.name}</span>
                <div className="flex gap-3 align-items-center">
                    <span className="text-2xl text-400">{t("averageSuccess")}:</span>
                    <Knob value={80} valueColor="#009C47" rangeColor="#D8D8D8" size={50} valueTemplate={'{value}%'} />
                </div>
            </div>
            <Accordion multiple className="exercise-details-accordion">
                <AccordionTab header={() => header(`${t("day")} 1 - ${t("Monday")}`)}>
                    <DataTable className="accordion-datatable" value={data} expandableRowGroups={false} rowExpansionTemplate={rowExpansionTemplate} expandedRows={expandedRows} onRowToggle={(e:any) => setExpandedRows(e.data)}>
                        <Column expander={true} style={{ width: '5rem' }} />
                        {accordionDatatableDynamicColumns}
                    </DataTable>
                </AccordionTab>
                <AccordionTab header={() => header(`${t("day")} 2 - ${t("Tuesday")}`)}>
                    <DataTable className="accordion-datatable" value={data} expandableRowGroups={false} rowExpansionTemplate={rowExpansionTemplate} expandedRows={expandedRows} onRowToggle={(e:any) => setExpandedRows(e.data)}>
                        <Column expander={true} style={{ width: '5rem' }} />
                        {accordionDatatableDynamicColumns}
                    </DataTable>
                </AccordionTab>
            </Accordion>
        </>
    )
}

export default ExerciseDetails;