import { Button } from "primereact/button";
import { Carousel } from "primereact/carousel";
import { Chart } from "primereact/chart";
import { useEffect, useState } from "react";

const ExerciseCard = ({ t, exercises, selectedExercise, setSelectedExercise } : any) => {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    const responsiveOptions = [
        {
            breakpoint: '1400px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '1199px',
            numVisible: 3,
            numScroll: 1
        },
        {
            breakpoint: '767px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '575px',
            numVisible: 1,
            numScroll: 1
        }
    ];

    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        const data = {
            labels: [t('completed'), t('incomplete')],
            datasets: [
                {
                    data: [80, 20],
                    backgroundColor: [
                        documentStyle.getPropertyValue('--green-600'),
                        documentStyle.getPropertyValue('--gray-200'), 
                    ],
                    hoverBackgroundColor: [
                        documentStyle.getPropertyValue('--green-600'),
                        documentStyle.getPropertyValue('--gray-200'), 
                    ]
                }
            ]
        }

        const options = {
            cutout: '60%',
            hover: {
                mode: null,
            },
            plugins: {
                tooltip: {
                    enabled: true,
                },
                legend: {
                    display: false,
                }
            },
        }

        setChartData(data);
        setChartOptions(options);
    }, [t])

    const template = (exercise : any) => {
        return (
            <div className="m-2 py-5 px-3 bg-indigo-50">
                <span className="text-center text-xl font-bold">{exercise.name}</span>
                <div className="flex justify-content-center align-items-center mt-3">
                    <Chart type="doughnut" data={chartData} options={chartOptions} className="w-10rem h-10rem" />
                </div>
                <div className="flex flex-column justify-content-center align-items-center">
                    <div className="flex">
                        <div className="flex flex-column p-3 gap-3">
                            <div className="flex flex-column">
                                <span className="text-md text-600">{t("set")}</span>
                                <span className="text-sm text-500">{exercise.set} {t("sets")}</span>
                            </div>
                            <div className="flex flex-column">
                                <span className="text-md text-600">{t("reference")}</span>
                                <span className="text-sm text-500">{exercise.reference} {t("degree")}</span>
                            </div>
                            <div className="flex flex-column">
                                <span className="text-md text-600">{t("rest")}</span>
                                <span className="text-sm text-500">{exercise.rest} {t("secs")}</span>
                            </div>
                        </div>
                        <div className="flex flex-column p-3 gap-3">
                            <div className="flex flex-column">
                                <span className="text-md text-600">{t("repeat")}</span>
                                <span className="text-sm text-500">{exercise.repeat} {t("times")}</span>
                            </div>
                            <div className="flex flex-column">
                                <span className="text-md text-600">{t("tolerance")}</span>
                                <span className="text-sm text-500">{exercise.tolerance} {t("degree")}</span>
                            </div>
                            <div className="flex flex-column">
                                <span className="text-md text-600">{t("period")}</span>
                                <span className="text-sm text-500">{t("Mor")}, {t("Eve")}</span>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-column">
                        <span className="text-md text-600">{t("days")}</span>
                        <span className="text-sm text-500">{t("Mon")}, {t("Tue")}, {t("Wed")}, {t("Thu")}</span>
                    </div>
                </div>
                <div className="flex justify-content-center mt-3">
                    <Button label={t("showDetails")} rounded severity="secondary" className={`${exercise === selectedExercise ? "bg-blue-900" : "bg-gray-500"}`} onClick={() => setSelectedExercise(exercise)} />
                </div>
            </div>
        )
    }

    return (
        <Carousel value={exercises} numVisible={4} numScroll={2} itemTemplate={template} showIndicators={false} responsiveOptions={responsiveOptions} />
    )
}

export default ExerciseCard;