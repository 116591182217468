import { Button } from "primereact/button"
import ExerciseCard from "./exerciseCard"
import SuccessRateChart from "./successRateChart"
import ExerciseDetails from "./exerciseDetails"
import { useState } from "react"

const ExerciseDetailsView = ({ t, setSeeAllPlans } : any) => {
    const [exercises, setExercises] = useState<any>([
        {
            name: "Arm Exercise",
            set: 3,
            repeat: 15,
            reference: 30,
            tolerance: 15,
            rest: 15
        },
        {
            name: "Exercise 2",
            set: 3,
            repeat: 15,
            reference: 30,
            tolerance: 15,
            rest: 15
        },
        {
            name: "Exercise 2",
            set: 3,
            repeat: 15,
            reference: 30,
            tolerance: 15,
            rest: 15
        },
        {
            name: "Exercise 2",
            set: 3,
            repeat: 15,
            reference: 30,
            tolerance: 15,
            rest: 15
        },
        {
            name: "Exercise 2",
            set: 3,
            repeat: 15,
            reference: 30,
            tolerance: 15,
            rest: 15
        },
        {
            name: "Exercise 2",
            set: 3,
            repeat: 15,
            reference: 30,
            tolerance: 15,
            rest: 15
        },
        {
            name: "Exercise 2",
            set: 3,
            repeat: 15,
            reference: 30,
            tolerance: 15,
            rest: 15
        },
        {
            name: "Exercise 2",
            set: 3,
            repeat: 15,
            reference: 30,
            tolerance: 15,
            rest: 15
        },
        {
            name: "Exercise 2",
            set: 3,
            repeat: 15,
            reference: 30,
            tolerance: 15,
            rest: 15
        },
        {
            name: "Exercise 2",
            set: 3,
            repeat: 15,
            reference: 30,
            tolerance: 15,
            rest: 15
        },
        {
            name: "Exercise 2",
            set: 3,
            repeat: 15,
            reference: 30,
            tolerance: 15,
            rest: 15
        },
    ])
    const [selectedExercise, setSelectedExercise] = useState<any>(exercises[0])

    return (
        <div className='flex flex-column gap-4'>
            <Button label='Neck Pain Reducing Plan' className='w-20rem' icon="pi pi-chevron-left" iconPos='left' text severity='secondary' onClick={() => setSeeAllPlans(true)} />
            <ExerciseCard t={t} exercises={exercises} selectedExercise={selectedExercise} setSelectedExercise={setSelectedExercise} />
            <SuccessRateChart t={t} selectedExercise={selectedExercise} />
            {selectedExercise && <ExerciseDetails t={t} selectedExercise={selectedExercise} />}
        </div>
    )
}

export default ExerciseDetailsView