import { Chart } from "primereact/chart"
import { useEffect, useState } from "react";

const SuccessRateChart = ({ t, selectedExercise } : any) => {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
        const data = {
            labels: ['', t('Monday'), t('Tuesday'), t('Wednesday'), t('Thursday'), t('Monday'), t('Tuesday'), t('Wednesday'), t('Thursday'), t('Monday'), t('Tuesday'), t('Wednesday')],
            datasets: [
                {
                    label: `${selectedExercise.name} - ${t("successRate")}`,
                    data: [0, 50, 60, 65, 70, 80, 90, 50, 60, 65, 70, 80],
                    fill: false,
                    borderColor: documentStyle.getPropertyValue('--blue-500'),
                    tension: 0
                },
            ]
        };
        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.6,
            plugins: {
                legend: {
                    labels: {
                        color: textColor
                    }
                },
                tooltip: {
                    enabled: true
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    },
                    beginAtZero: true,
                },
                y: {
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    },
                    beginAtZero: true,
                    min: 0,
                    max: 100,
                }
            }
        };

        setChartData(data);
        setChartOptions(options);
    }, [selectedExercise, t]);

    return (
        <div className="h-full w-full">
            <Chart type="line" data={chartData} options={chartOptions}  />
        </div>
    )
}

export default SuccessRateChart;