

import { useMutation, useQuery } from "@tanstack/react-query";
import { LazyParams } from "../services/lazyParams";
import { MobiTheraService } from "../services/mobiTheraService";


export const useGetPatientListQuery = () =>
  useMutation({
    mutationFn: (lazyParamsForPatients: any) => new MobiTheraService().getPatientList(lazyParamsForPatients),
  });

export const useGetAppointmentListQuery = () =>
  useMutation({
    mutationFn: (lazyParamsForAppointments: any) => new MobiTheraService().getAppointmentList(lazyParamsForAppointments),
  });

export const useGetExercisesListQuery = (lazyParamsForExercises: LazyParams) => {
  return useQuery({
    queryKey: ['exercisesList', lazyParamsForExercises],
    queryFn: () => new MobiTheraService().getExercisesList(lazyParamsForExercises),
  })
}

export const useGetPlanListByPatientQuery = (patientId: any) => {
  return useQuery({
    queryKey: ['planList', patientId],
    queryFn: () => new MobiTheraService().getPlanListById(patientId),
  });
};

export const useTokenCheck = () => {
  return useQuery({
    queryKey: ['tokenCheck'],
    queryFn: () => new MobiTheraService().tokenCheck(),
  });
}

export const useAddPatient = () =>
  useMutation({
    mutationFn: (patient: any) => new MobiTheraService().addPatientToList(patient),
  });

export const useAddPaln = () =>
  useMutation({
    mutationFn: (plan: any) => new MobiTheraService().addPlan(plan),
  });

export const useUpdateAppointmentStatus = () =>
  useMutation({
    mutationFn: (appointment: any) => new MobiTheraService().updateAppointmentStatus(appointment),
  });

export const useGetGoogleCredentials = () =>
  useMutation({
    mutationFn: (tokenResponse: any) => new MobiTheraService().getGoogleCredentials(tokenResponse),
  });

export const useConsultantLogin = () =>
  useMutation({
    mutationFn: (requestBody: any) => new MobiTheraService().consultantLogin(requestBody),
  })

export const useLoginWithApple = () =>
  useMutation({
    mutationFn: (requestBody: any) => new MobiTheraService().loginWithApple(requestBody),
  })

export const useLoginWithGoogle = () =>
  useMutation({
    mutationFn: (requestBody: any) => new MobiTheraService().loginWithGoogle(requestBody),
  })

export const useConsultantGetCalismaSonuc = () => 
  useMutation({
    mutationFn: (requestBody: any) => new MobiTheraService().consultantGetCalismaSonuc(requestBody),
  })

export const useConsultantGetTahsilSonuc = () =>
  useMutation({
    mutationFn: (requestBody: any) => new MobiTheraService().consultantGetTahsilSonuc(requestBody),
  })

export const useConsultantGetUshHizmetiSonuc = () =>
  useMutation({
    mutationFn: (requestBody: any) => new MobiTheraService().consultantGetUshHizmetiSonuc(requestBody),
  })