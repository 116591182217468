import { Card } from "primereact/card";
import { Carousel } from "primereact/carousel"
import { Chart } from "primereact/chart";
import { useEffect, useState } from "react"
import { useAddPaln } from "../hooks/useGetDataQuery";
import { useToastContext } from "../Lib/Contexts/toastContext";

const PlansCard = ({ plan, setPlan, planList, setPlanList, t }: any) => {
    const { showToast }: any = useToastContext();
    const [chartCompleteData, setChartCompletetData] = useState<any>();
    const [chartAdherenceData, setChartAdherencetData] = useState<any>();

    const [chartOptions, setChartOptions] = useState<any>();

    const {
        mutate: addPlanMutation,
        isSuccess: isAddPlanSuccess,
        isError: isAddPlanError
    } = useAddPaln();


    useEffect(() => {
        const dataOfCompleteArray = [];
        const dataOfAdherenceArray = [];

        if (planList) {
            for (const plan of planList) {
                const dataForComplete = {
                    id: plan.id,
                    labels: [t('complete'), t('incomplete')],
                    datasets: [
                        {
                            data: [86, 14],
                            backgroundColor: [
                                'rgba(227, 80, 107,1)',
                                'rgba(217, 217, 217,1)',
                            ],
                            hoverBackgroundColor: [
                                'rgba(227, 80, 107,0.5)',
                                'rgba(217, 217, 217,0.5)',
                            ]
                        }
                    ]
                }

                dataOfCompleteArray.push(dataForComplete)

                const dataForAdherence = {
                    id: plan.id,
                    labels: [t('adherence'), t('nonadherence')],
                    datasets: [
                        {
                            data: [30, 70],
                            backgroundColor: [
                                'rgba(80, 112, 227,1)',
                                'rgba(217, 217, 217,1)',
                            ],
                            hoverBackgroundColor: [
                                'rgba(80, 112, 227,0.5)',
                                'rgba(217, 217, 217,0.5)',
                            ]
                        }
                    ]
                }
                dataOfAdherenceArray.push(dataForAdherence);
            }
        }

        const options = {
            plugins: {
                legend: {
                    labels: {
                        usePointStyle: true
                    }
                }
            }
        };

        setChartOptions(options);

        setChartCompletetData(dataOfCompleteArray);
        setChartAdherencetData(dataOfAdherenceArray);
    }, [planList, t]);

    useEffect(() => {
        if (plan) {
            addPlanMutation(plan);
        }
    }, [plan]);

    useEffect(() => {
        if (isAddPlanSuccess) {
            showToast({
                severity: "success",
                summary: "Success",
                detail: "Exercises saved successfully!",
            });
            setPlanList((prevPlans: any) => prevPlans ? [...prevPlans, plan] : [plan])
            setPlan(undefined);
        }
    }, [isAddPlanSuccess]);

    useEffect(() => {
        if (isAddPlanError) {
            showToast({
                severity: "error",
                summary: "Error",
                detail: "Exercises could not be saved",
            });
            setPlan(undefined);
        }
    }, [isAddPlanError]);


    const dateBodyTemplate = (rowData: any, key: any) => {

        if (rowData[key]) {
            const formattedDate = new Date(rowData[key]).toLocaleDateString("tr-TR", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
            });
            return formattedDate.replace(", ", " ")
        };
    }

    const planTemplate = (plan: any) => {
        const background = plan.status === "Completed" ? "#E7F8EF" : plan.status === "InProgress" ? "#FDECE9" : "#D9D9D9"

        return (
            <Card title={plan.name} className='m-1' style={{ backgroundColor: "#FFFFFF" }}>
                <div className='flex flex-column justify-content-center'>
                    <div className='flex flex-row justify-content-center'>
                        <Chart type="doughnut" data={chartCompleteData?.find((c: any) => c.id === plan.id)} options={chartOptions} width='8rem' />
                        <Chart type="doughnut" data={chartAdherenceData?.find((c: any) => c.id === plan.id)} options={chartOptions} width='8rem' />
                    </div>
                    <div className='flex flex-row mt-2 align-items-center justify-content-center'>
                        <div className="border-round w-9rem h-4rem m-1 flex flex-column align-items-center justify-content-center pt-1" style={{ backgroundColor: "white" }}>
                            <p className='text-gray-400 -mt-2'>{t("dateOfStart")}</p>
                            <span className="font-bold "> {dateBodyTemplate(plan, "planDateStart")}</span>
                        </div>
                        <div className="border-round w-9rem h-4rem m-1 flex flex-column align-items-center justify-content-center pt-1" style={{ backgroundColor: "white" }}>
                            <p className='text-gray-400 -mt-2'>{t("dailyTime")}</p>
                            <span className="font-bold "> {plan.dailyTime ? plan.dailyTime : "--"} min</span>
                        </div>

                    </div>
                    <div className='flex flex-row justify-content-center mt-2'>
                        <div className="border-round w-9rem h-4rem m-1 flex flex-column align-items-center justify-content-center pt-1" style={{ backgroundColor: "white" }}>
                            <p className='text-gray-400 -mt-2'>{t("dateOfEnd")}</p>
                            <span className="font-bold "> {dateBodyTemplate(plan, "planDateEnd")}</span>
                        </div>
                        <div className="border-round w-9rem h-4rem m-1 flex flex-column align-items-center justify-content-center pt-1" style={{ backgroundColor: "white" }}>
                            <p className='text-gray-400 -mt-2'>{t("totalExercise")}</p>
                            <span className="font-bold ">{plan.totalExerciseCount ? plan.totalExerciseCount : plan.exerciseList?.length} {t("exercises")}</span>
                        </div>
                    </div>
                </div>
            </Card>
        )
    }


    return (
        <Carousel value={planList} numVisible={2} numScroll={1} itemTemplate={planTemplate} />
    )
}

export default PlansCard