import { Button } from "primereact/button"
import { Card } from "primereact/card"
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { countries } from "../Utils/countryList";
import "./consultantProfile.css";
import { useConsultantGetCalismaSonuc, useConsultantGetTahsilSonuc, useConsultantGetUshHizmetiSonuc } from "../hooks/useGetDataQuery";
import { useToastContext } from "../Lib/Contexts/toastContext";
import CalismaSonucDialog from "./Dialogs/calismaSonucDialog";
import TahsilSonucDialog from "./Dialogs/tahsilSonucDialog";

const ConsultantProfile = () => {

    const { t }: any = useOutletContext();
    const { showToast } : any = useToastContext();

    const {
        mutate: consultantGetCalismaSonucMutation,
        data: consultantGetCalismaSonucData,
        isError: isErrorConsultantGetCalismaSonuc,
        isSuccess: isSuccessConsultantGetCalismaSonuc,
    } = useConsultantGetCalismaSonuc();

    const {
        mutate: consultantGetTahsilSonucMutation,
        data: consultantGetTahsilSonucData,
        isError: isErrorConsultantGetTahsilSonuc,
        isSuccess: isSuccessConsultantGetTahsilSonuc,
    } = useConsultantGetTahsilSonuc();

    const {
        mutate: consultantGetUshHizmetiSonucMutation,
        data: consultantGetUshHizmetiSonucData,
        isError: isErrorConsultantGetUshHizmetiSonuc,
        isSuccess: isSuccessConsultantGetUshHizmetiSonuc,
    } = useConsultantGetUshHizmetiSonuc();


    const [consultatnName, setConsultatnName] = useState<any>(JSON.parse(localStorage.getItem("account") as any)?.firstName)
    const [consultatnLastName, setConsultatnLastName] = useState<any>(JSON.parse(localStorage.getItem("account") as any)?.lastName)

    const [phoneNumber, setPhoneNumber] = useState<any>()
    const [codeForPhoneNumber, setCodeForPhoneNumber] = useState<any>({ name: 'Turkey', code: 'TR', phoneCode: '+90' })

    const [identityNumber, setlIdentityNumber] = useState<any>()
    const [identityNumberIsNotGiven, setIdentityNumberIsNotGiven] = useState<any>()

    const [serialNumber, setSerialNumber] = useState<any>()
    const [serialNumberIsNotGiven, setSerialNumberIsNotGiven] = useState<any>()

    const [registrationNumber, setRegistrationNumber] = useState<any>()
    const [registrationNumberIsNotGiven, setRegistrationNumberIsNotGiven] = useState<any>()

    const [institutionCode, setInstitutionCode] = useState<any>()
    const [institutionCodeIsNotGiven, setInstitutionCodeIsNotGiven] = useState<any>()

    const [branchCode, setBranchCode] = useState<any>()
    const [branchCodeIsNotGiven, setBranchCodeIsNotGiven] = useState<any>()

    const [calismaSonucDialogVisible, setCalismaSonucDialogVisible] = useState<any>(false)
    const [tahsilSonucDialogVisible, setTahsilSonucDialogVisible] = useState<any>(false)

    const genderOptions = [
        {
            name: t("gender.Male"), value: "male"

        },
        {
            name: t("gender.Female"), value: "female"

        }
    ]
    const [gender, setGender] = useState<any>()



    const selectedCountryTemplateForPhone = (option: any, props: any) => {
        if (option) {
            return (
                <div className="flex align-items-center p-1">
                    <img alt={option.name}
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '20px', borderRadius: "12px" }} />
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const countryOptionTemplateForPhone = (option: any) => {
        return (
            <div className="flex align-items-center">
                <img alt={option.name} src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`} className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '20px', borderRadius: "12px" }} />
                <div>{t("countriesName." + option.name)}</div>
                <div className='ml-2'>{option.phoneCode}</div>
            </div>
        );
    };

    const validtationCheck = () => {
        let escape = false
        if (!identityNumber) {
            escape = true;
            setIdentityNumberIsNotGiven(true)
        }
        if (!serialNumber) {
            escape = true;
            setSerialNumberIsNotGiven(true)
        }
        if (!registrationNumber) {
            escape = true;
            setRegistrationNumberIsNotGiven(true)
        }

        return escape

    }

    const workResultsVerify = () => {
        if (validtationCheck()) {
            return;
        }

        const requestBody = {
            tckn: identityNumber,
            seriNo: serialNumber,
            tescilNo: registrationNumber
        }

        consultantGetCalismaSonucMutation(requestBody)
    }

    useEffect(() => {
        if(isSuccessConsultantGetCalismaSonuc) {
            if(!consultantGetCalismaSonucData?.Success) {
                showToast({ severity: 'error', summary: 'Error', detail: consultantGetCalismaSonucData?.Message });
            } else {
                setCalismaSonucDialogVisible(true);
            }
        }
    }, [isSuccessConsultantGetCalismaSonuc])

    useEffect(() => {
        if(isErrorConsultantGetCalismaSonuc) {
            showToast({ severity: 'error', summary: 'Error', detail: "Error occurred while getting Calisma Sonuc!" });
        }
    }, [isErrorConsultantGetCalismaSonuc])

    const collectionResultsVerify = () => {
        if (validtationCheck()) {
            return;
        }

        const requestBody = {
            tckn: identityNumber,
            seriNo: serialNumber,
            tescilNo: registrationNumber
        }

        consultantGetTahsilSonucMutation(requestBody)
    }

    useEffect(() => {
        if(isSuccessConsultantGetTahsilSonuc) {
            if(!consultantGetTahsilSonucData?.Success) {
                showToast({ severity: 'error', summary: 'Error', detail: consultantGetTahsilSonucData?.Message });
            } else {
                setTahsilSonucDialogVisible(true);
            }
        } 
    }, [isSuccessConsultantGetTahsilSonuc])

    useEffect(() => {
        if(isErrorConsultantGetTahsilSonuc) {
            showToast({ severity: "error", summary: "Error", detail: "Error occurred while getting Tahsil Sonuc!" })
        }
    }, [isErrorConsultantGetTahsilSonuc])

    const verifyInstitutionCode = () => {
        let escape = false
        if (!institutionCode) {
            escape = true;
            setInstitutionCodeIsNotGiven(true)
        }
        if (!branchCode) {
            escape = true;
            setBranchCodeIsNotGiven(true)
        }

        if (escape) {
            return
        }

        const requestBody = {
            kurumNo: institutionCode,
            bransKod: branchCode
        }

        consultantGetUshHizmetiSonucMutation(requestBody)
    }

    useEffect(() => {
        if(isSuccessConsultantGetUshHizmetiSonuc) {
            if(!consultantGetUshHizmetiSonucData?.Success) {
                showToast({ severity: 'error', summary: 'Error', detail: consultantGetUshHizmetiSonucData?.Message });
            } else {
                showToast({ severity: 'success', summary: 'Success', detail: `Kurum dogrulamasi: ${consultantGetUshHizmetiSonucData?.Data.UshHizmetSonuc}` });
            }
        }
    }, [isSuccessConsultantGetUshHizmetiSonuc])


    useEffect(() => {
        if(isErrorConsultantGetUshHizmetiSonuc) {
            showToast({  severity: "error", summary: "Error", detail: "Error occurred while getting Ush Hizmeti Sonuc!" })
        }
    }, [isErrorConsultantGetUshHizmetiSonuc])

    return (
        <>
            <div className="grid">
                <div className="col-12">
                    <Card style={{ borderRadius: "24px", position: "relative", minWidth: "900px" }}>
                        <div className="flex justify-content-between pt-2 px-4">
                            <p className="m-0 text-xl" style={{ color: "#727A99" }}>{t("profileInfo")}</p>
                            <Button
                                label={t("saveChanges")}
                                icon={<img src="/accept.svg" />}
                                style={{
                                    backgroundColor: "#E3506B",
                                    color: "white",
                                    border: "#E3506B",
                                    height: "2.5rem",
                                    borderRadius: "10px",
                                    width: "15rem",
                                    fontWeight: "bold"
                                }}
                            />
                        </div>

                        <div className="flex justify-content-start align-items-start gap-6 p-4">

                            <img src="/man.png" style={{ width: "12rem", height: "12rem", borderRadius: "100px" }} />

                            <div className="flex flex-column justify-content-center align-items-center w-full gap-4">
                                <div className="flex w-full flex-column gap-2">
                                    <label>{t("name")}</label>
                                    <InputText value={consultatnName}
                                        style={{ borderRadius: "10px" }}

                                        onChange={e => setConsultatnName(e.target.value)} />
                                </div>

                                <div className="flex flex-column gap-2  w-full">
                                    <label htmlFor="gender">{t("genderWord")}</label>
                                    <div className=" flex align-content-center align-items-center ">
                                        <Dropdown className="w-full" style={{ height: '3rem', borderRadius: "10px" }} value={gender} options={genderOptions} optionLabel="name"

                                            onChange={e => setGender(e.target.value)} />
                                    </div>
                                </div>

                            </div>
                            <div className="flex flex-column justify-content-center align-items-center w-full gap-4">
                                <div className="flex w-full flex-column gap-2">
                                    <label>{t("lastName")}</label>
                                    <InputText value={consultatnLastName}
                                        style={{ borderRadius: "10px" }}

                                        onChange={e => setConsultatnLastName(e.target.value)} />

                                </div>

                                <div className="flex flex-column gap-2 consultatnProfile w-full">
                                    <label htmlFor="phone">{t("phoneNumber")}</label>
                                    <div className="combo-box flex align-content-center align-items-center ">
                                        <Dropdown style={{ height: '3rem', width: '6rem' }} value={codeForPhoneNumber} options={countries} optionLabel="name"
                                            valueTemplate={selectedCountryTemplateForPhone} itemTemplate={countryOptionTemplateForPhone}
                                            onChange={e => setCodeForPhoneNumber(e.target.value)} />
                                        <InputNumber className='w-full' value={phoneNumber} onChange={(e) => setPhoneNumber(e.value)} useGrouping={false} style={{ height: "3rem" }} />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Card>
                </div>
                <div className="col">
                    <Card style={{ borderRadius: "24px", position: "relative" }}>
                        <p className="m-0 text-xl pt-2 px-4" style={{ color: "#727A99" }}>{t("doctorVerification")}</p>
                        <div className="flex flex-column justify-content-start align-items-center w-full pt-4 px-4 gap-4">


                            <div className="flex justify-content-center align-items-center gap-4 w-full">
                                <div className="flex w-full flex-column gap-2">
                                    <label>{t("identityNumber")} <i className="pi pi-asterisk p-overlay-badge" style={{ fontSize: '0.5rem', color: 'red' }}></i></label>
                                    <InputText value={identityNumber}
                                        style={{ borderRadius: "10px" }}
                                        className={identityNumberIsNotGiven ? identityNumber ? undefined : "p-invalid mr-2" : undefined}
                                        onChange={e => setlIdentityNumber(e.target.value)} />
                                </div>
                                <div className="flex w-full flex-column gap-2">
                                    <label>{t("serialNumber")} <i className="pi pi-asterisk p-overlay-badge" style={{ fontSize: '0.5rem', color: 'red' }}></i></label>
                                    <InputText value={serialNumber}
                                        style={{ borderRadius: "10px" }}
                                        className={serialNumberIsNotGiven ? serialNumber ? undefined : "p-invalid mr-2" : undefined}
                                        onChange={e => setSerialNumber(e.target.value)} />
                                </div>
                            </div>

                            <div className="flex w-full flex-column gap-2">
                                <label>{t("registrationNumber")} <i className="pi pi-asterisk p-overlay-badge" style={{ fontSize: '0.5rem', color: 'red' }}></i></label>
                                <InputText value={registrationNumber}
                                    style={{ borderRadius: "10px" }}
                                    className={registrationNumberIsNotGiven ? registrationNumber ? undefined : "p-invalid mr-2" : undefined}
                                    onChange={e => setRegistrationNumber(e.target.value)} />
                            </div>

                            <Button
                                onClick={workResultsVerify}
                                className="w-full"
                                style={{
                                    backgroundColor: "#2D3967",
                                    color: "white",
                                    border: "#E3506B",
                                    height: "3rem",
                                    borderRadius: "10px",
                                    fontWeight: "bold"
                                }}
                            >
                                <span className="flex justify-content-center align-items-center gap-2 font-bold" style={{ width: "100%" }}>
                                    <img src="/accept.svg" />
                                    {t("workingResultsVerify")}
                                </span>
                            </Button>


                            <Button
                                onClick={collectionResultsVerify}
                                className="w-full"
                                style={{
                                    backgroundColor: "#E3506B",
                                    color: "white",
                                    border: "#E3506B",
                                    height: "3rem",
                                    borderRadius: "10px",
                                    fontWeight: "bold"
                                }}
                            >
                                <span className="flex justify-content-center align-items-center gap-2 font-bold" style={{ width: "100%" }}>
                                    <img src="/accept.svg" />
                                    {t("collectionResultsVerify")}
                                </span>
                            </Button>



                        </div>

                    </Card>
                </div>
                <div className="col ">
                    <Card className="h-full" style={{ borderRadius: "24px", position: "relative" }}>
                        <p className="m-0 text-xl pt-2 px-4" style={{ color: "#727A99" }}>{t("institutionVerification")}</p>
                        <div className="flex flex-column justify-content-start align-items-center w-full pt-4 px-4 gap-4">

                            <div className="flex w-full flex-column gap-2">
                                <label>{t("institutionCode")} <i className="pi pi-asterisk p-overlay-badge" style={{ fontSize: '0.5rem', color: 'red' }}></i></label>
                                <InputText value={institutionCode}
                                    style={{ borderRadius: "10px" }}
                                    className={institutionCodeIsNotGiven ? institutionCode ? undefined : "p-invalid mr-2" : undefined}
                                    onChange={e => setInstitutionCode(e.target.value)}
                                    tooltip={t("institutionCodeTooltip")} tooltipOptions={{ position: 'top' }} />
                            </div>

                            <div className="flex w-full flex-column gap-2">
                                <label>{t("branchCode")} <i className="pi pi-asterisk p-overlay-badge" style={{ fontSize: '0.5rem', color: 'red' }}></i></label>
                                <InputText value={branchCode}
                                    style={{ borderRadius: "10px" }}
                                    className={branchCodeIsNotGiven ? branchCode ? undefined : "p-invalid mr-2" : undefined}
                                    onChange={e => setBranchCode(e.target.value)} />
                            </div>

                            <Button
                                onClick={verifyInstitutionCode}
                                className="w-full"
                                style={{
                                    backgroundColor: "#2D3967",
                                    color: "white",
                                    border: "#E3506B",
                                    height: "3rem",
                                    borderRadius: "10px",
                                    fontWeight: "bold"
                                }}
                            >
                                <span className="flex justify-content-center align-items-center gap-2 font-bold" style={{ width: "100%" }}>
                                    <img src="/accept.svg" />
                                    {t("verify")}
                                </span>
                            </Button>

                        </div>

                    </Card>
                </div>
            </div>
            <TahsilSonucDialog tahsilSonucDialogVisible={tahsilSonucDialogVisible} setTahsilSonucDialogVisible={setTahsilSonucDialogVisible} data={consultantGetTahsilSonucData} />
            <CalismaSonucDialog calismaSonucDialogVisible={calismaSonucDialogVisible} setCalismaSonucDialogVisible={setCalismaSonucDialogVisible} data={consultantGetCalismaSonucData} />
        </>
    )
}

export default ConsultantProfile