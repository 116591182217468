import { Dialog } from "primereact/dialog"
import "./patientCodeDialog.css"
import { Button } from "primereact/button";
import { Trans } from "react-i18next";

const PatientCodeDialog = ({ codeDialogVisible, code, t, carePlanName, selectedExercises, onCodeDialogHide } : any) => {
    const getToday = () => {
        const today = new Date();
        const day = today.getDate().toString().padStart(2, '0');
        const month = (today.getMonth() + 1).toString().padStart(2, '0');
        const year = today.getFullYear();

        return `${day} / ${month} / ${year}`;
    }

    return (
        <Dialog 
            className="code-dialog" 
            visible={codeDialogVisible} 
            style={{ width: '25rem', borderRadius: '24px', overflow: "hidden", padding: 0 }} 
            onHide={onCodeDialogHide}
            showHeader={false}
        >
            <div className="flex flex-column">
                <div className="p-4" style={{background: '#2B3674', minHeight: '120px'}}>
                    <div className="flex justify-content-between align-items-start">
                        <span className="text-2xl font-bold text-white mb-1">{carePlanName}</span>
                        <div className="flex gap-2">
                            <Button className="p-button-rounded p-button-text" style={{color: 'white'}}>
                                <i className="pi pi-print" />
                            </Button>
                            <Button className="p-button-rounded p-button-text" style={{color: 'white'}} onClick={onCodeDialogHide}>
                                <i className="pi pi-times" />
                            </Button>
                        </div>
                    </div>
                    <span className="flex justify-content-end underline text-400">{getToday()}</span>
                </div>
                <div className="p-4">
                    <h2 className="text-xl mb-3" style={{color: '#2B3674'}}>{t("exercises")}</h2>
                    <div className="flex flex-column gap-2" style={{ height: "10rem", overflowY: "auto" }}>
                        {selectedExercises && selectedExercises.map((exercise: any) => (
                            <span className="text-lg" style={{color: '#A3AED0'}}>{exercise.exercise.name}</span>
                        ))}
                    </div>
                </div>
                <div className="p-4" style={{background: '#E6FAF5'}}>
                    <div className="flex justify-content-between align-items-center gap-2">
                        <div className="flex flex-column">
                            <Trans i18nKey="prescriptionMessageText" components={{ bold: <strong className="font-bold" /> }} />
                        </div>
                        <i className="pi pi-check-circle" style={{color: '#01B574', fontSize: '1.8rem'}} />
                    </div>
                </div>
                <div className="flex">
                    <div className="flex justify-content-center align-items-center w-full" style={{background: '#F52B70'}}>
                        <div className="flex flex-column">
                            <span className="text-white font-medium">{t("registerCode")}</span>
                            <span className="text-white text-4xl font-bold">{code}</span>
                        </div>
                    </div>
                    <div className="flex justify-content-center align-items-center w-full p-4" style={{background: '#2B3674'}}>
                        <span className="text-white">{t("giveCodeToPatientText")}</span>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default PatientCodeDialog;