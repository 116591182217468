import { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import PlansCard from './plansCard';
import { useGetPlanListByPatientQuery } from '../hooks/useGetDataQuery';
import { BlockUI } from 'primereact/blockui';
import "./prescription.css"
import ExerciseDetailsView from './exerciseDetailsView';
import AddExercisesDialog from '../Utils/ExerciseDialog/addExercisesDialog';
import PatientDetailCard from './patientDetailCard';
import PatientHistory from './patientHistory';
import AppointmentTable from '../Utils/Tables/appointmentTable';
import { Card } from 'primereact/card';


const Prescription = () => {
    const { t }: any = useOutletContext();
    const { appointmentListWithAllData }: any = useOutletContext();
    const { lazyParamsForAppointments }: any = useOutletContext();


    const [exerciseDialogVisible, setExerciseDialogVisible] = useState<any>();

    const [selectedPatient, setSelectedPatient] = useState<any>(JSON.parse(localStorage.getItem("patient") || '{}'));

    const { data, isSuccess, isError } = useGetPlanListByPatientQuery(selectedPatient?.id);

    const [seeAllPlans, setSeeAllPlans] = useState<boolean>(true);

    const [planList, setPlanList] = useState<any>([])
    const [plan, setPlan] = useState<any>()
    const [blockUI, setBlockUI] = useState(true);

    const [tabIndex, setTabIndex] = useState<any>(0)


    const [painDateOptions, setPainDateOptions] = useState<any>(
        [
            {
                name: "22/7/2023",
                value: new Date(2023, 7, 22),
                anemnez: "",
                notes: ""
            },
            {
                name: "22/8/2023",
                value: new Date(2023, 8, 22),
                anemnez: "",
                notes: ""
            },
            {
                name: "22/9/2023",
                value: new Date(2023, 9, 22),
                anemnez: "",
                notes: ""
            }

        ]
    )

    const [paitentHistoryDate, setPaitentHistoryDate] = useState<any>(painDateOptions[0].value)

    const [allUserAppointments, setAllUserAppointments] = useState<any>(0)


    useEffect(() => {
        if (appointmentListWithAllData) {
            setAllUserAppointments(appointmentListWithAllData.filter((appointment: any) => appointment.patientId === selectedPatient.id))
        }
    }, [appointmentListWithAllData]);


    useEffect(() => {
        if (isSuccess) {
            setPlanList(data?.data)
            setBlockUI(false);
        }
    }, [isSuccess, data]);

    useEffect(() => {
        if (isError) setBlockUI(false);
    }, [isError]);

    return (
        <BlockUI
            blocked={blockUI}
            fullScreen
            style={{ backgroundColor: "transparent" }}
            template={
                <i className="pi pi-spin pi-spinner" style={{ fontSize: "3rem" }}></i>
            }
        >
            {selectedPatient ? (
                <div className="flex flex-column justify-content-center">
                    <PatientDetailCard
                        t={t}
                        selectedPatient={selectedPatient}
                        planList={planList}
                        tabIndex={tabIndex}
                        setTabIndex={setTabIndex}
                        setExerciseDialogVisbible={setExerciseDialogVisible}
                        paitentHistoryDate={paitentHistoryDate}
                        setPaitentHistoryDate={setPaitentHistoryDate}
                        painDateOptions={painDateOptions} />
                    <br />

                    {tabIndex === 0 &&
                        <>
                            {seeAllPlans ? (
                                <>
                                    {/* <div className='flex justify-content-center '>
                                        <Button icon="pi pi-plus" label={t("addPlan")} style={{ backgroundColor: "#FE9797", border: "#FE9797" }}
                                            onClick={() => setExerciseDialogVisbible(true)} />
                                    </div> */}

                                    <PlansCard t={t} plan={plan} setPlan={setPlan} planList={planList} setPlanList={setPlanList} />
                                    {/* <div className='flex justify-content-center align-items-center'>
                                        <Button label='22.10.2024' icon="pi pi-calendar" iconPos='left' outlined severity='secondary' onClick={() => setSeeAllPlans(false)} />
                                    </div>
                                    <PlansStatistics t={t} /> */}
                                </>
                            ) : (
                                <ExerciseDetailsView t={t} setSeeAllPlans={setSeeAllPlans} />
                            )}
                        </>}


                    {tabIndex === 1 &&
                        <>
                            <PatientHistory
                                paitentHistoryDate={paitentHistoryDate}
                                painDateOptions={painDateOptions}
                                setPainDateOptions={setPainDateOptions}
                                t={t} />
                        </>
                        }

                    {tabIndex === 2 &&
                        <>
                            <Card style={{ borderRadius: "24px", position: "relative" }}>
                                <AppointmentTable
                                    upcomingAppointments={allUserAppointments}
                                    lazyParamsForAppointments={lazyParamsForAppointments}
                                    t={t}
                                />
                            </Card>

                        </>}
                    {/* <Card className='h-full'>
                        <TabView>
                            <TabPanel header={t("patientInformation")} leftIcon="pi pi-user mr-2">
                                <div className="flex">
                                    <UserCard selectedPatient={selectedPatient} t={t} />
                                    <PatientInformation selectedPatient={selectedPatient} t={t} />
                                </div>
                            </TabPanel>
                            <TabPanel header={t("plansAndProgress")} leftIcon="pi pi-list-check mr-2">
                                {seeAllPlans ? (
                                    <>
                                        <div className='flex justify-content-center'>
                                            <Button icon="pi pi-plus" label={t("addPlan")} style={{ backgroundColor: "#FE9797", border: "#FE9797" }}
                                                onClick={() => setExerciseDialogVisible(true)} />
                                        </div>
                                        <br />
                                        <PlansCard t={t} plan={plan} setPlan={setPlan} planList={planList} setPlanList={setPlanList} />
                                        <div className='flex justify-content-center align-items-center'>
                                            <Button label='22.10.2024' icon="pi pi-calendar" iconPos='left' outlined severity='secondary' onClick={() => setSeeAllPlans(false)} />
                                        </div>
                                        <PlansStatistics t={t} />
                                    </>
                                ) : (
                                    <ExerciseDetailsView t={t} setSeeAllPlans={setSeeAllPlans} />
                                )}
                            </TabPanel>
                            <TabPanel header={t("appointments")} leftIcon="pi pi-calendar mr-2">
                                <PrescriptionAppointments selectedPatient={selectedPatient} appointmentListWithAllData={appointmentListWithAllData} />
                            </TabPanel>
                        </TabView>
                    </Card> */}
                    <AddExercisesDialog t={t} planList={planList} selectedPatient={selectedPatient} exerciseDialogVisible={exerciseDialogVisible} setExerciseDialogVisible={setExerciseDialogVisible} setPlan={setPlan} />
                </div>
            ) : (
                <div className="h-30rem">
                    <div className="flex flex-column justify-content-center align-items-center p-5 h-full">
                        <i className="pi pi-exclamation-circle" style={{ color: '#E3506B', fontSize: "5rem" }}></i>
                        <span className="text-2xl text-center mt-2">{t("noRegisteredPatientFound")}</span>
                        <div className="flex align-items-center gap-2 mt-3">
                            <i className='pi pi-question-circle text-orange-500' style={{ fontSize: "2rem" }}></i>
                            <span className="text-xl text-center text-orange-500">
                                {t("warningMessageForNoRegisteredPatient")}
                            </span>
                        </div>
                    </div>
                </div>
            )}
        </BlockUI>
    )
}

export default Prescription